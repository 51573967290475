import { ref, watch } from "vue";
import { convertCalendars } from "@/helpers/convertCalendars";
import { formatDate, formatHijriDate } from '@/helpers/helpers';
import { computed } from "vue";

export function useDateConverter(initialGregorianDate = null) {
  const toHijriDate = (gregDate) => {
    try {
      return convertCalendars(
        "gregorian",
        gregDate.getFullYear(),
        gregDate.getMonth() + 1,
        gregDate.getDate()
      );
    } catch (error) {
      return gregDate;
    }
  };
  const toGregorianDate = (hijDate) => {
    try {
      return convertCalendars(
        "hijri",
        hijDate.getFullYear(),
        hijDate.getMonth() + 1,
        hijDate.getDate(),
        { fromCal: "islamic-umalqura" }
      );
    } catch (error) {
      return hijDate;
    }
  };

  const gregorianDate = ref(initialGregorianDate ?? new Date());
  const hijriDate = ref(toHijriDate(gregorianDate.value));

  const stringGregorianDate = computed(() => formatDate(gregorianDate.value))
  const stringHijriDate = computed(() => formatHijriDate(hijriDate.value))

  watch(stringGregorianDate, () => {
    const newHijriDate = toHijriDate(gregorianDate.value);
    // Avoid infinite loop by checking if the new value is different
    if (JSON.stringify(newHijriDate) !== JSON.stringify(hijriDate.value)) {
      hijriDate.value = newHijriDate;
    }
  });

  watch(stringHijriDate, () => {
    const newGregorianDate = toGregorianDate(hijriDate.value);
    // Avoid infinite loop by checking if the new value is different
    if (
      JSON.stringify(newGregorianDate) !== JSON.stringify(gregorianDate.value)
    ) {
      gregorianDate.value = newGregorianDate;
    }
  });

  return {
    hijriDate,
    gregorianDate,
    stringHijriDate,
    stringGregorianDate,
    useToHijriDate: toHijriDate,
    useToGregorianDate: toGregorianDate,
  };
}
