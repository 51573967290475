<template>
    <div>
        <div v-if="loading" class="bg-white d-flex flex-wrap justify-content-center">
            <BaseSpinner :size="4" class="py-5" />
        </div>
        <div v-if="charities.length" class="bg-white pb-5 d-flex flex-wrap justify-content-center">
            <div class="row mx-2 mx-md-4 d-flex flex-wrap justify-content-center">
                <div class="text-center fw-bold fs-2 py-5" data-cy="cases-list-header">مرضى الجمعيات</div>
                <div class="charity-card-board d-flex flex-wrap justify-content-center">
                    <div class="charity-card-board__items" v-for="charity in charities" :key="index">
                        <CharityCard class="charity-card-board__item" :charity="charity" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-center bg-white fw-bold fs-2 py-5">لا يوجد الجمعيات</div>
        </div>
        <div class="row" v-if="charities?.length">
            <Paginator :rows="9" :totalRecords="totalItemsCount" @page="onPageChange($event)"></Paginator>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import CharityCard from "./CharityCard.vue"
import BaseSpinner from "@/components/layout/BaseSpinner.vue";
import CharityService from "@/services/charity.service";

const loading = ref(false)
let charities = reactive([])
const queryParams = {
    page: 1,
    page_size: 9,
    group: null,
    specialty: null
}
const totalItemsCount = ref(9)
onMounted(() => {
    getCharityList(queryParams)
});
const getCharityList = (queryParams) => {
    loading.value = true
    CharityService.getCharityList(queryParams)
    .then(({ data: { result } }) => {
        charities = result?.results
        totalItemsCount.value = result?.total_objects;
    }).finally(() => {
        loading.value = false
    })
}
const onPageChange = (event) => {
    queryParams.page = event.page + 1;
    getCharityList(queryParams)
};
</script>

<style lang="scss" scoped>
.charity-card-board {
    &__items {
        max-width: 1125px;
    }
    &__item {
    flex: 0 0 calc(33% - 50px);
    min-width: 300px;
    max-width: 310px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 30px !important;
  }
  max-width: 1125px!important;
}
::v-deep(.p-paginator) {
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    transform: rotate(180deg);
  }
}
</style>