<template>
  <Card class="card-board__item case-card-item">
    <template #header>
      <div class="position-relative">
        <img class="condition-img"
          loading="lazy"
          :src="(case_.urls?.case_image && case_.urls?.case_image[0]) || case_.urls?.id_photo"
          alt="صورة الحالة">
        <div class="overlay"></div>
        <img class="charity-image position-absolute" loading="lazy" :src="case_.urls?.charity_image" alt="صورة الجمعية">
      </div>
      <div class="remaining_amount_card">
        <div class="d-flex justify-content-center align-items-center p-3 color-primary">
          <div class="completed-icon"></div>
          <div>بعطائكم اكتملت فرصة التبرع</div>
        </div>
        <div class="remaining_amount_card__details">
          <div>
            <span>{{ case_.id }}</span>
            <span class="color-complementary">#</span>
          </div>
          <span class="color-complementary">تفاصيل الحالة</span>
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <div class="row">
          <div class="mt-2 fw-bold title" :title="case_.story_title">
            {{ case_.story_title }}
          </div>
        </div>
        <div class="row">
          <div class="condition-description mt-2" :title="case_.story">
            {{ case_.story }}
          </div>
        </div>
        <div class="row">
          <div class="d-flex align-items-center justify-content-around h-50p">
            <div class="col-6 text-center d-flex justify-content-center align-items-center">
              <div>
                <i class="pi pi-map-marker color-primary mx-2"></i>
              </div>
              <div>
                <span class="fw-semibold">{{ case_.area_name }}</span>
              </div>
            </div>
            <div class="col-6 text-center d-flex justify-content-center align-items-center">
              <div>
                <img class="speciality-icon mx-2" alt="speciality icon"
                  loading="lazy"
                  :src="case_.specialty_image"
                  v-if="case_.specialty_image">
                <img class="speciality-icon mx-2" alt="speciality icon"
                  loading="lazy"
                  :src="`${staticUrl}/static/icons/generic-case-icon.svg`"
                  v-else>
              </div>
              <div>
                <span class="fw-semibold">{{ case_.specialty_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center align-items-center">
        <div class="text-center color-gray flex-1">مكتملة</div>
        <DonorCaseShareIcon
          class="me-2 h-100"
          :sharable-link="sharableLink"
          header="ساهم في العلاج عن طريق مشاركتك الحالة للآخرين"
          :show-signin-info="false"
          :authenticated="!$store.getters.isAuthenticated"
          aria-label="share case"
          variant="legacy"
          @open="openShareDialog"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import BaseSpinner from "@/components/layout/BaseSpinner.vue";

import JwtUtilService from "@/services/jwt.util.service";
import AmbassadorService from "@/services/ambassador.service";
import DonorCaseShareIcon from "@/components/campaigns/DonorCaseShareIcon";
import { displayResponseErrors } from "@/hooks/useDisplayResponseErrors";

export default {
  name: "completed-case-card",
  inject: ['staticUrl'],
  components: { BaseSpinner, DonorCaseShareIcon },
  props: {
    case_: {},
    close: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sharableLink: `${process.env.VUE_APP_FE_URL}/donate-case/${this.$props.case_?.id}`,
      canDonate: JwtUtilService.getClientID()?.includes("doner") || !this.$store.getters.isAuthenticated,
      canShareTrackableLink: JwtUtilService.getClientID()?.includes("doner") && this.$store.getters.isAuthenticated,
      FEURL: process.env.VUE_APP_FE_URL,
      loading: false,
    };
  },
  methods: {
    openShareDialog() {
      if (this.canShareTrackableLink) {
        this.createLink();
      }
    },
    async createLink() {
      this.loading = true;
      await AmbassadorService.postCaseNumber({ case: this.case_?.id })
        .then((response) => {
          const result = response.data.result;
          this.sharableLink = `${this.FEURL}/ambassador-link/${result.id}`;
          this.loading = false;
        })
        .catch((err) => {
          // displayResponseErrors(err?.response?.data?.errors, this.$toast)
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/abstracts/mixins";

.case-card-item {
  width: 100%;
  max-width: 450px;
  margin: auto auto 30px;
  padding: 10px 10px 0 8px;
  background-color: white;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  min-height: 500px;
  -webkit-box-shadow: 0 3px 10px 1px rgba(153, 153, 153, 0.27);
  -moz-box-shadow: 0 3px 10px 1px rgba(153, 153, 153, 0.27);
  box-shadow: 0 3px 10px 1px rgba(153, 153, 153, 0.27);
  font-size: 14px;

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .remaining_amount_card {
    width: 90%;
    margin: auto;
    background-color: white;
    border-radius: 0 0 9px 9px;
    box-shadow: $box-shadow-tile;
    z-index: 10;
    top: 140px;
    right: 23px;
    overflow: hidden;

    &__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 600;
      background-color: $color-gray-lighter;
      border-radius: 0 0 8px 8px;
    }
  }

  .condition-img {
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    vertical-align: middle;
  }

  .overlay {
    position: absolute;
    inset: 0;
    background: black;
    opacity: 1;
    mix-blend-mode: color;
  }

  .charity-image {
    width: 50px;
    height: 50px;
    top: 5px;
    left: 5px;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  }

  .condition-description {
    color: #585858;
    font-size: 15px;
    height: 72px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .completed-icon {
    width: 41px;
    height: 45px;
    background-image: url(#{$static-url}/static/icons/completedicon.svg);
  }

  a {
    color: white;
    text-decoration: none;
  }
}

::v-deep(.p-card-content) {
  padding: 0 !important;
  border-bottom: 1px solid #e5e5e5;
}

.dir-rtl {
  direction: rtl;
}

.color-primary {
  color: $color-primary;
}

.color-gray {
  color: $color-font-lighter;
}

.h-50p {
  height: 50px;
}

.color-complementary {
  color: $color-complementary;
}

.flex-1 {
  flex: 1;
}
</style>
