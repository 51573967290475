<template>
    <Card class="charity-card charity-card-board__item d-flex flex-column justify-content-center">
        <template #header>
            <div class="charity-card-board__card d-flex flex-column justify-content-center align-items-center pt-4" style="height: 200px;">
                <VLazyImage :src="charity.logo_url" 
                    alt="charity logo" 
                    class="charity-logo w-auto h-100"
                    style="object-fit: contain;"
                />
            </div>
        </template>
        <template #content>
            <div class="d-flex flex-column align-items-center justify-content-center border-top pt-4">
                <h5 class="text-center">{{ charity.name }}</h5>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center mt-3">
                <button class="btn btn-primary text-white px-4" @click="redirectToCharityCasesList">مرضى الجمعية</button>
            </div>
        </template>
    </Card>
</template>

<script setup>
import { defineProps } from 'vue';
import VLazyImage from "v-lazy-image";
import { useRouter } from 'vue-router';

const router = useRouter()

const props = defineProps({
    charity: {
      type: Object,
      required: true
    }
});

const redirectToCharityCasesList = () => {
  router.push(`/charity/${props.charity.id}/cases-list`);
};
</script>

<style scoped>
.charity-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2431372549)
}
.charity-logo {
    max-width: 200px;
    max-height: 300px;
}
</style>