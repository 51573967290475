import axios from "axios";

const baseURL = process.env.VUE_APP_API_URL;

class CharityService {
  static getCharityList({ page, page_size, type }: any) {
    let url = `${baseURL}/charity/cards?page=${page}&page_size=${page_size}`;
    url += type && type !== "" ? `&${type}=1` : "";

    return axios.get(url);
  }
}

export default CharityService;