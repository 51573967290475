import HijriDate, { toHijri } from 'hijri-date/lib/safe'
import HijrahDate from 'hijrah-date'
import { formatHijriDate } from '@/helpers/helpers'
import { convertCalendars } from './convertCalendars';

function hijrahFromHijriObjetct(hijriObject) {
  return new HijrahDate(hijriObject.getFullYear(), hijriObject.getMonth(), hijriObject.getDate());
}

// hijrah-dates become invalid for years < 1300 and years > 1600
// Greg equivalent of < 1883 and > 2174
// there are fallback methods for these cases, although they are not as accurate

HijriDate.prototype.getMonthLength = function () {
  try {
    return hijrahFromHijriObjetct(this).getMonthLength();
  } catch (error) {
    try {
      let date = 28;
      let lastDay = 0;

      // find the last day of the hijri month by adding one day to the date until the month changes
      // this is a fallback method for old hijri dates
      // the hijri date is considered old if it is before 1300
      // we are converting to gregorian and getting the next day (because this is more accurate), and then back to hijri to see if the month has changed
      while (!lastDay && date < 31) {
        let fulldDate = new HijriDate(this.getFullYear(), this.getMonth(), date);

        let gregorianDate = new Date(fulldDate.toGregorian());
        let nextGregorianDate = new Date(gregorianDate.setDate(gregorianDate.getDate() + 1));

        let nextHijriDate;
        try {
          nextHijriDate = toHijri(nextGregorianDate);
        } catch (error) {
          nextHijriDate = convertCalendars("gregorian", nextGregorianDate.getFullYear(), nextGregorianDate.getMonth() + 1, nextGregorianDate.getDate());
        }

        if (nextHijriDate.getMonth() !== this.getMonth()) {
          lastDay = date;
        }
        date++;
      }

      console.warn(`Hijri date provided, ${formatHijriDate(this)}, is very old, number of days in the hijri month may not be correct.`);
      return lastDay;
    } catch (error) {
      // console.log(error);
      console.warn(`Hijri date provided, ${formatHijriDate(this)}, is very old. The number of days in the hijri month may is not accurate and has been generalized to 30.`);
      return 30;
    }
  }
}


HijriDate.prototype.oldGetDay = HijriDate.prototype.getDay;

// getDay() method from hijraah-date package is more accourate than the one from hijri-date package
HijriDate.prototype.getDay = function () {
  try {
    return hijrahFromHijriObjetct(this).getDay();
  } catch (error) {
    console.warn(`Hijri date provided, ${formatHijriDate(this)}, is very old, the hijri weekdays may be incorrect.`);
    try {
      return this.oldGetDay();
    } catch (error) {
      throw error;
    }
  }
}

export default HijriDate;

export {
  toHijri
}