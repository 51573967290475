import { useHead } from '@vueuse/head';
export function useMetaTags(metaTags) {
  useHead({
    meta: metaTags.map(tag => ({
      key: tag.key,
      name: tag.name,
      content: tag.content,
      property: tag.property,
    }))
  });
}